const state = {
	currentUser: JSON.parse(localStorage.getItem('currentUser')),
	currentInstitution: JSON.parse(localStorage.getItem('currentInstitution')),
	accessToken: localStorage.getItem('accessToken'),
	refreshToken: localStorage.getItem('refreshToken'),
	allRooms: JSON.parse(localStorage.getItem('allRooms')),
	userSubjects: JSON.parse(localStorage.getItem('userSubjects')),
	allBuses: JSON.parse(localStorage.getItem('allBuses')),
}

const getters = {
	accessToken: state => state.accessToken,
	refreshToken: state => state.refreshToken,
	currentUser: state => state.currentUser,
	currentInstitution: state => state.currentInstitution,
	allRooms: state => state.allRooms,
	userSubjects: state => state.userSubjects,
	allBuses: state => state.allBuses,
}

const actions = {

	async setTokens({
		commit
	}, tokens) {
		await commit('saveTokens', tokens);
	},

	// sets current user
	async setCurrentUser({
		commit
	}, currentUser) {
		commit('saveCurrentUser', currentUser);
	},

	// sets current user
	async setCurrentInstitution({
		commit
	}, currentInstitution) {
		commit('saveCurrentInstitution', currentInstitution);
	},

	async clearTokens({
		commit
	}) {
		commit('removeToken');
	},

	// Executed while LOGOUT
	async logoutUser({
		commit
	}) {
		// IMP : Also Send logout info to backend to end session 
		commit('removeCurrentUser');
		commit('removeToken');
		commit('removeEverything');

	},

	async unsetCurrentUser({
		commit
	}) {
		commit('removeCurrentUserFromState');

	},

	async setAllRooms({
		commit
	}, allRooms) {
		commit('setAllRooms', allRooms);
	},

	async setUserSubjects({
		commit
	}, subjects) {
		commit('setUserSubjects', subjects);
	},

	async setAllBuses({
		commit
	}, allBuses) {
		commit('setAllBuses', allBuses);
	},
}

const mutations = {
	saveCurrentUser(state, currentUser) {
		localStorage.setItem('currentUser', JSON.stringify(currentUser));
		state.currentUser = currentUser;
	},

	saveCurrentInstitution(state, currentInstitution) {
		localStorage.setItem('currentInstitution', JSON.stringify(currentInstitution));
		state.currentInstitution = currentInstitution;
	},
	setAllRooms(state, allRooms) {
		localStorage.setItem('allRooms', JSON.stringify(allRooms));
		state.allRooms = allRooms;
	},
	setAllBuses(state, allBuses) {
		localStorage.setItem('allBuses', JSON.stringify(allBuses));
		state.allBuses = allBuses;
	},

	setUserSubjects(state, subjects) {
		localStorage.setItem('userSubjects', JSON.stringify(subjects));
		state.userSubjects = subjects;
	},

	removeCurrentUser(state) {
		localStorage.removeItem('currentUser');
		state.currentUser = null;
	},

	removeCurrentUserFromState(state) {
		state.currentUser = null;
	},

	saveTokens(state, tokenData) {
		localStorage.setItem('accessToken', tokenData.access);
		localStorage.setItem('refreshToken', tokenData.refresh);
		state.accessToken = tokenData.access;
		state.refreshToken = tokenData.refresh;
	},

	removeToken(state) {
		localStorage.removeItem('accessToken');
		localStorage.removeItem('refreshToken');
		state.token = null;
	},

	removeEverything(state) {
		localStorage.clear();
		state.accessToken = null;
		state.refreshToken = null;
		state.token = null;
		state.currentUser = null;
		state.currentInstitution = null;
	},

}


export default {
	state,
	getters,
	actions,
	mutations
};