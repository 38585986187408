const state = {
    // adminRoom: {
    //     room: null,
    //     roomName: "",
    //     firstSection: null,
    //     secondSection: null,
    //     thirdSection: null,
    // },
    appBarData: {
        visible: true,
        title: null,
        iconButton: null,
        actions: [], 
        color: "primary"
    },
    isLoading: false,
    snackbar: {
        title: null,
        text: "",
        visible: false,
        color: 'dark',
        timeout: 3500,
        type: null,
        action: null,
    },
    lastSelectedRoom: null,
    onRefresh: null,

    lastNotificationId: JSON.parse(localStorage.getItem('lastNotificationId')),
    newNotificationsCount: null

}

const getters = {
    // adminRoom: state => state.adminRoom,
    isLoading: state => state.isLoading,
    snackbar: state => state.snackbar,
    appBarData: state => state.appBarData,
    lastSelectedRoom: state => state.lastSelectedRoom,
    onRefresh: state => state.onRefresh,
    lastNotificationId: state => state.lastNotificationId,
    newNotificationsCount: state => state.newNotificationsCount,
}

const actions = {
    setLoading({
        commit
    }, loading) {
        commit('saveLoading', loading);
    },

    setSections({
        commit
    }, sectionsData) {
        commit('saveSections', sectionsData);
    },

    setAdminRoom({
        commit
    }, roomData) {
        commit('saveAdminRoom', roomData);
    },
    clearAdminRoom({
        commit
    }) {
        console.log("Clearing Admin Room");
        commit('clearAdminRoom');
    },
    showSnackbar({
        commit
    }, snackbarData) {
        if (!snackbarData.timeout) snackbarData.timeout = 5000;
        if (!snackbarData.type && !snackbarData.color) snackbarData.color = 'dark';
        if(!snackbarData.action) snackbarData.action = null;
        if (snackbarData.type) snackbarData.color = snackbarData.type;
        snackbarData.visible = true;
        commit('displaySnackbar', snackbarData)
    },

    changeAppBarVisibility({
        commit
    }, visible) {
        commit('changeAppBarVisibilityState', visible);
    },
    setAppBarOptions({
        commit
    }, actions) {
        commit('setAppBarOptions', actions);
    },
    resetAppBarOptions({
        commit
    }) {
        commit('setAppBarOptions', {});
    },
    setAppBarTitle({
        commit
    }, title) {
        commit('setAppBarTitle', title);
    },
    setLastSelectedRoom({
        commit
    }, room) {
        commit('setLastSelectedRoom', room);
    },
    setOnRefresh({
        commit
    }, onRefresh) {
        commit('setOnRefresh', onRefresh);
    },
    setLastNotificationId({commit }, id){
        commit('setLastNotificationId', id);
    },
    setNewNotificationsCount({commit }, id){
        commit('setNewNotificationsCount', id);
    }
}

const mutations = {
    saveLoading(state, loading) {
        state.isLoading = loading;
    },
    saveSections(state, sectionsData) {
        state.adminRoom.firstSection = sectionsData.firstSection;
        state.adminRoom.secondSection = sectionsData.secondSection;
        state.adminRoom.thirdSection = sectionsData.thirdSection;
    },
    displaySnackbar(state, snackbar) {
        state.snackbar = snackbar;
    },
    setAppBarTitle(state, title) {
        state.appBarData.title = title;
    },
    changeAppBarVisibilityState(state, visible) {
        state.appBarData.visible = visible;
    },
    setAppBarOptions(state, options) {
        state.appBarData.actions = options.actions && options.actions.length ? options.actions : [];
        state.appBarData.iconButton = options.iconButton ?? null;
        state.appBarData.visible = options.visible ?? true;
        state.appBarData.color = options.color ?? "primary";
    },
    setLastSelectedRoom(state, room) {
        state.lastSelectedRoom = room;
    },
    setOnRefresh(state, onRefresh) {
        state.onRefresh = onRefresh;
    },
    setLastNotificationId(state, id) {
		localStorage.setItem('lastNotificationId', JSON.stringify(id));

        state.lastNotificationId = id;
    },
    setNewNotificationsCount(state, id) {
        state.newNotificationsCount = id;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}