<template>
  <div class="notification-icon">
    <v-btn icon :to="{ name: 'Notifications' }" class="non-focused">
      <div v-if="fetched">
        <v-badge
          v-if="newNotificationsCount != null && !isLoading"
          :content="newNotificationsCount"
          :value="newNotificationsCount"
          color="red"
          overlap
        >
          <v-icon> mdi-bell </v-icon>
        </v-badge>

        <v-badge v-else color="red" overlap :dot="!isLoading">
          <v-icon> mdi-bell </v-icon>
        </v-badge>
      </div>
      <v-icon v-else>mdi-bell</v-icon>
    </v-btn>
    <confirmation-dialog
      title="New update!"
      :description="`Please click on 'Update' to quickly update your app to the next version ${
        frontendVersion ? '(' + frontendVersion.latest_version + ')' : ''
      }!`"
      persistent
      :visible="showUpdateDialog"
      @successCallback="updateApp"
      :failure-text="
        frontendVersion && frontendVersion.mandatory ? null : 'Later'
      "
      @failureCallback="showUpdateDialog = false"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Mixins from "@utils/mixins";
import EventBus from "@utils/event_bus";
export default {
  name: "NotificationIcon",
  mixins: [Mixins.essentials],
  data() {
    return {
      fetched: false,
      showUpdateDialog: false,
      frontendVersion: null,
    };
  },
  computed: {
    ...mapGetters(["lastNotificationId", "newNotificationsCount"]),
  },
  methods: {
    ...mapActions(["setNewNotificationsCount"]),

    async updateNotificationsCount() {
      // if last id is null, don't make the call since last id is mandatory
      if (!this.lastNotificationId) {
        this.fetched = true;
        return;
      }
      var url =
        this.endpoints.checkNewNotificationsCount +
        this.lastNotificationId +
        "/";
      // used to get the latest version for this frontend endpoint
      url = this.Helper.addUrlParams(
        url,
        `frontend_url=${this.endpoints.webAppLink.replace("https://", "")}`
      );
      var response = await this.api.call(this.essentials, url);
      var serverNewNotificationsCount = response.count;
      this.manageVersioning(response.versioning);
      if (serverNewNotificationsCount != this.newNotificationsCount)
        this.setNewNotificationsCount(serverNewNotificationsCount);

      this.fetched = true;
    },

    compareVersions(a, b) {
      var i, diff;
      var regExStrip0 = /(\.0+)+$/;
      var segmentsA = a.replace(regExStrip0, "").split(".");
      var segmentsB = b.replace(regExStrip0, "").split(".");
      var l = Math.min(segmentsA.length, segmentsB.length);

      for (i = 0; i < l; i++) {
        diff = parseInt(segmentsA[i], 10) - parseInt(segmentsB[i], 10);
        if (diff) {
          return diff;
        }
      }
      return segmentsA.length - segmentsB.length;
    },

    async manageVersioning(frontendVersion) {
      if (!frontendVersion) return;
      console.log(
        frontendVersion.latest_version,
        this.endpoints.currentVersion
      );
      let shouldUpdate =
        this.compareVersions(
          frontendVersion.latest_version,
          this.endpoints.currentVersion
        ) > 0; // compare versions : returns positive number if not on latest
      if (!shouldUpdate) return;
      this.frontendVersion = frontendVersion;
      this.showUpdateDialog = true; //show dialog
    },

    async updateApp() {
      var platform = await this.Helper.getDevicePlatform();
      if (this.frontendVersion.soft_update) {
        this.showSnackbar({ title: "Updating...", type: "info" }),
        setTimeout(
          () => window.location.reload(), // refreshes the screen to automatically get the latest version (only in case of soft update)
          500
        );
        
        return;
      }
      // store update
      if (platform == "android") window.open(this.endpoints.playStoreLink); // redirect to google play store if android
      if (platform == "ios") window.open(this.endpoints.appStoreLink);
      // redirect to apple app store if ios
      else window.location.reload();
    },
  },
  watch: {
    lastNotificationId: {
      handler(newValue, oldValue) {
        if (newValue > oldValue) return;
        this.updateNotificationsCount();
      },
      immediate: true,
    },
  },
  created() {
    console.log(this.newNotificationsCount);
    EventBus.$on("notifications__new_notification_received", () =>
      this.setNewNotificationsCount(this.newNotificationsCount + 1)
    );
  },
};
</script>