const SERVER_URL = "https://backend.intractapp.in/";  
// const SERVER_URL = "http://app.intractapp.in:8001/";  
// const SERVER_URL = "http://localhost:8000/";
// const SERVER_URL = "http://192.168.0.196:8000/";
// const SERVER_URL = "http://localhost:8000/";
// const SERVER_URL = "http://192.168.1.144:8000/";
// const SERVER_URL = "http://192.168.29.37:8000/";
// const SERVER_URL = "http://192.168.1.204:8000/";

const endpoints = {
	currentVersion: '4.3.1',
	appTitle: 'Intract for Institutes',
	bundleId: 'com.intract.ifi',
	playStoreLink: 'https://play.google.com/store/apps/details?id=com.intract.ifi',
	appStoreLink: 'https://apps.apple.com/in/app/intract-for-institutes/id1513759986',
	webAppLink: 'https://web.intractapp.in',
	feesPortalLink: 'https://fees.intractapp.in',
    // Auth endpoints
    obtainJWT: SERVER_URL + 'jauth/jwt/create/',
	refreshJWT: SERVER_URL + 'jauth/jwt/refresh/',
	authenticateSubordinateUser: SERVER_URL + 'base/users/subordinate/authenticate/',
	getMyDetails: SERVER_URL + 'base/users/me/',
	getInstitutionDetails: SERVER_URL + 'base/institution/id/',
	getRoomFromSections: SERVER_URL + 'base/rooms/get/',
	checkInstitutionCode: SERVER_URL + 'base/institution/code/check/',
	getInstitutionDetailsByCode: SERVER_URL + 'base/institution/code/',
	getInstitutionDetailsById: SERVER_URL + 'base/institution/id/',
	getInstitutionDetailsByBundleId: SERVER_URL + 'base/institution/bundle/',
	getDefaultInstitutionCode: SERVER_URL + 'base/get_default_institution_code/',
	defaultProfileImage: SERVER_URL + 'media/users/default/default.jpg',
	saveFCMToken: SERVER_URL + 'user/save_fcm_token/',
	logoutUser: SERVER_URL + 'base/user/logout/',

	requestSupport: SERVER_URL + 'support/request/create/',

	// changePassword: SERVER_URL + 'erp/staff/password/change/',
	// sendPasswordResetEmail: SERVER_URL + 'erp/staff/password/reset/email/',
	// verifyResetPasswordToken: SERVER_URL + 'erp/staff/password/reset/token/verify/',
	resetPassword: SERVER_URL + 'base/user/password/reset/',
	changePassword: SERVER_URL + 'base/user/password/change/',
	// resetStaffPassword: SERVER_URL + 'erp/base/staff/password/reset/',

	getPhoneFromUsername: SERVER_URL + 'base/user/get_phone_from_username/',
	
	// base
	institutionsViewSet: SERVER_URL + 'base/institutions/',
	getRoom: SERVER_URL + 'base/rooms/get/',
	getAllRooms: SERVER_URL + 'base/rooms/all/',
	getAllRoomsWithTitleOnly: SERVER_URL + 'base/rooms_with_title_only/all/',

	// user profile
	userProfileViewSet: SERVER_URL + 'base/user-profiles/',

	// notifications
	checkNewNotificationsCount: SERVER_URL + 'notifications/count/check/',
	notificationsViewSet: SERVER_URL + 'misc/notifications/all/',
	
	// students
	registerStudent: SERVER_URL + 'base/register/student/',
	approveStudent: SERVER_URL + 'base/approve/student/',
	studentViewSet: SERVER_URL + 'base/students/',
	softDeleteCurrentStudentRecords: SERVER_URL + 'erp/students/delete/current/',
	
	staffViewSet: SERVER_URL + 'base/staff/',
	softDeleteStaffRecords: SERVER_URL + 'erp/staff/delete/soft/',

	// Import Students
	importExcelStudents: SERVER_URL + 'erp/students/import/data/',
	
	classroomViewSet: SERVER_URL + 'base/classrooms/',
	subjectViewSet: SERVER_URL + 'subjects/',
	// for qr scanner
	getSubjectByRefNo: SERVER_URL + 'subjects/refno/',

	checkUsernameExists: SERVER_URL + "base/user/check_username_exists/",
	checkPhoneNumberExists: SERVER_URL + "base/user/check_phonenumber_exists/",
	
	// board
	boardPosts: SERVER_URL + 'board/posts/',
	boardPostReplyViewSet: SERVER_URL + 'board/post-replies/',


	// books
	bookTopics: SERVER_URL + 'books/topics/',
	bookTopicItems: SERVER_URL + 'books/topics/items/',
	topicIntroductions: SERVER_URL + 'books/introductions/',
	topicAssignments: SERVER_URL + 'books/assignments/',
	topicAssignmentSubmissions: SERVER_URL + 'books/assignment-submissions/',
	topicAssignmentSubmissionReplies: SERVER_URL + 'books/assignment-submission-replies/',

	// homework
	homeworkViewSet: SERVER_URL + 'homework/homework/',
	homeworkSubmissionViewSet: SERVER_URL + 'homework/submissions/',
	homeworkSubmissionReplyViewSet: SERVER_URL + 'homework/submission-replies/',

	// admin
	adminPanelCounts: SERVER_URL + 'admin-panel/home/info/',

	// videos
	videoConferences: SERVER_URL + 'videos/video-conferences/',
	
	// fees
	studentFeesDetailViewSet: SERVER_URL + 'fees/students/details/',
	feeTransactionViewSet: SERVER_URL + 'fees/transactions/',
	institutionFeePreferences: SERVER_URL + 'fees/preferences/',

	//timetable
	timetableViewSet: SERVER_URL + 'timetable/daily/items/',

	// video lectures
	videolecturesModulesViewSet: SERVER_URL + 'videos/video-modules/',
	videolecturesViewSet: SERVER_URL + 'videos/video-lectures/', // main feature URL

	// feedback 
	feedbackViewSet: SERVER_URL + 'feedback/posts/', 

	// ask 
	questionViewSet: SERVER_URL + 'ask/questions/',
	answerViewSet: SERVER_URL + 'ask/answers/',

	// holidays 
	holidaysViewSet: SERVER_URL + 'holidays/',
	
	// gallery
	galleryPostViewSet: SERVER_URL + 'gallery/posts/',

	//attendance
	attendanceViewSet: SERVER_URL + 'attendance/records/',
	classroomAttendanceViewSet: SERVER_URL + 'attendance/classroom/',

	// magazine
	magazineViewSet: SERVER_URL + 'magazine/posts/',

	// events
	eventsViewSet: SERVER_URL + 'events/',
	
	// exams
	examPosts: SERVER_URL + 'exams/',

	//report cards
	reportCardViewSet: SERVER_URL + 'report-card/report-cards/',
	reportCardGroupViewSet: SERVER_URL + 'report-card/report-card-group/',
	reportCardParameterGroupViewSet: SERVER_URL + 'report-card/report-card-parameter-group/',
	reportCardParameterViewSet: SERVER_URL + 'report-card/report-card-parameter/',
	reportCardGradeViewSet: SERVER_URL + 'report-card/report-card-grade/',
	reportCardGroupStudentViewSet: SERVER_URL + 'report-card/report-card-group/students/',

	// tests
	onlineTestViewSet: SERVER_URL + 'tests/online-tests/',
	baseTestsUrl: "https://tests.intractapp.in/",

	// planner 
	plannerActivityViewSet: SERVER_URL + 'planner/activities/',
	activityTypeViewSet: SERVER_URL + 'planner/activity-types/',


	// analytics
	institutionAnalyticsViewSet: SERVER_URL + 'analytics/institution/get/',
	featuresAnalyticsViewSet: SERVER_URL + 'analytics/features/get/',
	krafAnalyticsViewSet: SERVER_URL + 'analytics/kraf/get/',
	krafBookAnalyticsViewSet: SERVER_URL + 'analytics/kraf/books/details/',
	krafActivityAnalyticsViewSet: SERVER_URL + 'analytics/kraf/activity_shetts/details/',
	// circles
	circlesViewSet: SERVER_URL + 'circles/circle-articles/',
	circleTagViewSet: SERVER_URL + 'circles/circle-tags/',
	circleCommentViewSet: SERVER_URL + 'circles/circle-comments/',

	// bus
	allBuses: SERVER_URL + 'bus/buses/',
	busLocations: SERVER_URL + 'bus/locations/',
	
	static: {
		studentImportFile: SERVER_URL + 'static/import_files/Students_SampleImport.xls',
		studentImportFileApp: SERVER_URL + 'static/import_files/Students_SampleImport_App.xls',
	},

}

export default endpoints;   