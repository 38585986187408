import PullToRefresh from "pulltorefreshjs";

const Helper = {
  // variables/constants
  dateURLFormat: 'yyyy-M-D H:mm:ss',

  urlRegExp: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
  

  handleErrors(errorResponse, handler) {
    if (errorResponse instanceof Error) {
      console.log(errorResponse.response);
      handler(errorResponse);
      return false;
    }
    return true;
  },
  // getRoomName(room) {
  //     let title = "";
  //     if (room == null) return title;

  //     if (room.first_section != null) {
  //         title = title + `${room.first_section.title}`;
  //     }
  //     if (room.second_section != null) {
  //         title = title + ` | ${room.second_section.title}`;
  //     }
  //     if (room.third_section != null) {
  //         title = title + ` | ${room.third_section.title}`;
  //     }
  //     return title;
  // },
  async toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  },

  // async dataURLtoFile(dataurl, filename) {
  //   var arr = dataurl.split(','),
  //     mime = arr[0].match(/:(.*?);/)[1],
  //     bstr = atob(arr[1]),
  //     n = bstr.length,
  //     u8arr = new Uint8Array(n);
  //   while (n--) {
  //     u8arr[n] = bstr.charCodeAt(n);
  //   }
  //   return new File([u8arr], filename, {
  //     type: mime
  //   });
  // },


  getExportName() {
    return "export_" + new Date().toLocaleDateString()
  },
  // uuidv4() {
  //     return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
  //         var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
  //         return v.toString(16);
  //     });
  // }
  totalSum(items, prop) {

    if (items == null) {
      return 0;
    }
    return items.reduce(function (a, b) {
      return b[prop] == null ? parseFloat(a || 0) : parseFloat(a || 0) + parseFloat(b[prop] || 0);
    }, 0);
  },

  currencyFormat(amount) {
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "INR",
    });
    return formatter.format(amount);
  },

  inWords(num) {
    var a = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
    var b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
    if ((num = num.toString()).length > 9) return 'overflow';
    var n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    var str = '';
    str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
    str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
    str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
    str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
    str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) : '';
    str += 'rupees only ';
    console.log("Amount in words :", str)
    return str;
  },

  // number2text(value) {
  //   var fraction = Math.round(frac(value) * 100);
  //   var f_text = "";

  //   if (fraction > 0) {
  //     f_text = "AND " + convert_number(fraction) + " PAISE";
  //   }

  //   return convert_number(value) + " RUPEES " + f_text + " ONLY";
  // },

  // gets nested properties using dot notations findProp(obj, 'a.b.s.c')
  getProp(obj, path) {
    var args = path.split("."),
      i,
      l;

    for (i = 0, l = args.length; i < l; i++) {
      if (!Object.prototype.hasOwnProperty.call(obj, args[i])) return;
      obj = obj[args[i]];
    }

    return obj;
  },

  // Helper.replaceTextFromArray(string, [['aa', 'bb'], ['cc','dd']])
  replaceTextFromArray(main, list_of_pairs) {
    list_of_pairs.map(pair => {
      if (main.includes(pair[0])) {
        main = main.replace(pair[0], pair[1]);
      }
    });

    return main;
  },

  addUrlParams(base_url, params) {
    if (!Array.isArray(params)) params = [params];
    var url = base_url
    params.forEach((param) => {
      url += (url.split("?")[1] ? "&" : "?") + param;
    })
    return url;
  },

  guidGenerator() {
    var S4 = function () {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
  },

  generateUID() {
    return this.guidGenerator();
  },

  secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    // var s = Math.floor(d % 3600 % 60);

    // var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    // var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
    // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    // return hDisplay + mDisplay + sDisplay; 

    return `${h}:${m}`
  },

  prettySecondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? "h:" : "h:") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? "m:" : "m:") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? "s" : "s") : "";
    return hDisplay + mDisplay + sDisplay;

  },

  getRateFromSeconds(seconds, rate) {
    seconds = Number(seconds)
    var rate_per_second = Number(rate) / 3600
    var amount = seconds * rate_per_second
    return parseFloat(amount).toFixed(2)

  },

  clone(obj) {
    var copy;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
      copy = [];
      for (var i = 0, len = obj.length; i < len; i++) {
        copy[i] = this.clone(obj[i]);
      }
      return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
      copy = {};
      for (var attr in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, attr)) copy[attr] = this.clone(obj[attr]);
      }
      return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
  },

  // {
  //   name: "Add Student",
  //   breadcrumb: "Students > Add",
  //   link: "/students/add",
  //   count: 10,
  // }

  obscureEmail(email) {
    var coreEmail = email.split('@')[0];
    var subString = coreEmail.substring(Math.round(coreEmail.length / 2), coreEmail.length);

    email = email.replace(subString, "*".repeat(subString.length));
    return email;
  },

  getCurrentFiscalYearDates() {
    var today = new Date();
    return [
      new Date(today.getMonth() > 2 ? today.getFullYear() : today.getFullYear() - 1, 3, 1),
      new Date(today.getMonth() > 2 ? today.getFullYear() + 1 : today.getFullYear(), 2, 31)
    ];
  },

  getFiscalYear(date) {
    var fiscalyear = "";
    if ((date.getMonth() + 1) <= 3) {
      fiscalyear = (date.getFullYear() - 1) + "-" + date.getFullYear()
    } else {
      fiscalyear = date.getFullYear() + "-" + (date.getFullYear() + 1)
    }
    return fiscalyear
  },

  async initPullRefresh(refreshFunction, prefix = 'ptr--') {
    return PullToRefresh.init({
      classPrefix: prefix,
      mainElement: "body",
      distMax: 200,
      distReload: 150,
      distIgnore: 30,
      distThreshold: 170,
      onRefresh() {
        console.log(refreshFunction)
        refreshFunction();
      },
    });
  },

  async destroyPullRefresh() {
    PullToRefresh.destroyAll();
  },

  // urlify(text) {
  //   var urlRegex = /(https?:\/\/[^\s]+)/g;

  // },

  getYouTubeThumbnailURL(link) {
    console.log(link);
    link = link.replace(/shorts\//g, '');
    link = link.replace(/youtube.com/g, 'youtu.be');
    var VID_REGEX =
      /(?:youtube(?:-nocookie)?\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    try {
      var videoId = link.match(VID_REGEX)[1];
      return `https://img.youtube.com/vi/${videoId}/0.jpg`;
    } catch (e) {
      console.log(e)
      return null;
    }

  },

  markuptext(text, identifier, htmltag) {
    var array = text.split(identifier);
    var previous = "";
    var previous_i;
    for (var i = 0; i < array.length; i++) {
      if (i % 2) {
        //odd number
      } else if (i != 0) {
        previous_i = eval(i - 1);
        array[previous_i] = "<" + htmltag + ">" + previous + "</" + htmltag + ">";
      }
      previous = array[i];
    }
    var newtext = "";
    for (i = 0; i < array.length; i++) {
      newtext += array[i];
    }
    return newtext;
  },

  async copyText(textToCopy) {
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
      // navigator clipboard api method'
      return navigator.clipboard.writeText(textToCopy);
    } else {
      // text area method
      let textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      // make the textarea out of viewport
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise((res, rej) => {
        // here the magic happens
        document.execCommand("copy") ? res() : rej();
        textArea.remove();
      });
    }
  },

  async openUrl(url) {
    const getBrowser = () => import("@capacitor/browser");
    const Browser = (await getBrowser()).Browser;
    Browser.open({
      url: url
    })
  },

  async getDevicePlatform() {
    const getDevice = () => import("@capacitor/device");
    const Device = (await getDevice()).Device;
    var info = await Device.getInfo();
    return info.platform;
  },

  async getDevice() {
    const getDevice = () => import("@capacitor/device");
    const Device = (await getDevice()).Device;
    return Device;
  },

  truncateText(text, n) {
    if (text.length > n) {
      return text.substring(0, n) + "..."
    }

    return text
  },

  // async dynamicImport(dependencyPath, defaultImport = true, neededObject = null){
  //   const getImport = () => import(dependencyPath);
  //   console.log(await getImport());
  //   if (defaultImport)return (await getImport()).default;
  //   else return (await getImport())[neededObject]
  // },


  // async handleDelete(context, parentEl, title, description, url, successText) {
  //   const Vue = import('vue').then(v => v);
  //   console.log(Vue);
  //   const ConfirmationDialog = await this.dynamicImport('@components/dialogs/ConfirmationDialog');
  //   console.log(Vue, ConfirmationDialog);
  //   const DeleteConfirmationDialogComponent = {
  //     props: ['showDeleteDialog', 'title', 'description', 'deleteObject'],
  //     components: {
  //       ConfirmationDialog
  //     },
  //     template: '<confirmation-dialog :visible="showDeleteDialog" :title="title" :description="description" @successCallback="deleteObject" @failureCallback="showDeleteDialog = false" delete-dialog/>',
  //   };
  //   const DeleteComponent = Vue.extend(DeleteConfirmationDialogComponent);

  //   async function deleteObject() {
  //     var response = await context.api.call(context.essentials, url, context.api.Methods.DELETE);
  //     if (response) {
  //       context.showSnackbar({
  //         title: successText,
  //         type: 'success'
  //       });
  //       return true;
  //     }
  //   }
  //   new DeleteComponent({
  //     propsData: {
  //       showDeleteDialog: true,
  //       title: title,
  //       description: description,
  //       deleteObject: deleteObject
  //     }
  //   }).$mount(parentEl);

  // },



}

export default Helper;